<template>
  <div :class="['custom-table', isChooseList ? 'choose' : '']">
    <div v-if="data.length" class="custom-table__container">
      <div class="custom-table__header" :style="getRowStyle">
        <div v-if="isNumbered" class="custom-table__header-item custom-table__number-of-item">
          #
        </div>
        <div
          :class="[
            'custom-table__header-item',
            !isElementShowed(columnName) && 'custom-table__none-item',
          ]"
          v-for="columnName in Object.keys(prepareData[0])"
          :key="columnName.id"
        >
          <div class="custom-table__header-item--container" v-if="isElementShowed(columnName)">
            <div class="custom-table__header-item--text">{{ prepareColumnName(columnName) }}</div>
            <div
              v-if="columnName != 'groups' && columnName != 'created_by_user' && handleFilter"
              class="custom-table__filter"
              @click="handleFilter(columnName)"
            >
              <div
                :class="[
                  'custom-table__filter--asc custom-table__filter-item',
                  columnName == order && direction === true ? 'active' : '',
                ]"
              >
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66846 5.04297L0.33833 0.542968L8.99858 0.542969L4.66846 5.04297Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                :class="[
                  'custom-table__filter--desc custom-table__filter-item',
                  columnName == order && direction === false ? 'active' : '',
                ]"
              >
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 9 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.66846 5.04297L0.33833 0.542968L8.99858 0.542969L4.66846 5.04297Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isActionBlockVisible"
          class="custom-table__action-block"
          :style="{ width: actionHeaderBlockWidth }"
        ></div>
      </div>
      <div class="custom-table__body">
        <div
          v-for="(row, index) in prepareData"
          :key="index"
          :class="['custom-table__row', getRowClass(row)]"
          :style="getRowStyle"
          @click="handleChoose ? handleChoose(row) : () => {}"
        >
          <div v-if="isNumbered" class="custom-table__row-item custom-table__number-of-item">
            {{ index + 1 }}
          </div>
          <div
            v-for="(item, key) in row"
            :key="key"
            @click="
              onActionColumn.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    onActionColumn.find((column) => column.columnName === key),
                    row,
                  )
                : booleanSwitcher.some((column) => column.columnName === key)
                ? handleActionOnClick(
                    booleanSwitcher.find((column) => column.columnName === key),
                    row,
                  )
                : () => {}
            "
            :class="[
              'custom-table__row-item',
              !isElementShowed(key) && 'custom-table__none-item',
              onCustomStyleColumn.some((column) => column.columnName === key) &&
                onCustomStyleColumn.find((column) => column.columnName === key).className,
            ]"
          >
            <template v-if="isElementShowed(key)">
              <div v-if="isImage(key)">
                <a v-if="row.images_preview" :href="row.images_preview" target="_blank">
                  <img :src="item" />
                </a>
                <img v-else :src="item" />
              </div>
              <template v-else-if="isSwitcher(key)">
                <div :class="[item ? 'switcher' : 'switcher switcher--disabled']">
                  {{ item ? "yes" : "no" }}
                </div>
              </template>
              <div
                v-else-if="key === 'status_id' && name === 'templates' && row.statusFull != null"
                :class="[row.statusFull.id === 4 ? 'link' : '']"
              >
                {{ item }}
              </div>
              <div v-else-if="key === 'id' && name === 'templates'">
                <router-link class="link" :to="getUrl(item, 'templates')">{{ item }}</router-link>
              </div>
              <div v-else-if="key === 'id' && name === 'categories'">
                <router-link class="link" :to="getUrl(item, 'categories')">{{ item }}</router-link>
              </div>
              <div v-else-if="key === 'id' && name === 'keywords'">
                <router-link class="link" :to="getUrl(item, 'keywords')">{{ item }}</router-link>
              </div>
              <div v-else-if="key === 'status' && name != 'templates'">
                {{ item ? "active" : "not active" }}
              </div>
              <div v-else-if="key === 'active'">
                {{ item ? "active" : "not active" }}
              </div>
              <div v-else>{{ item }}</div>
            </template>
          </div>
          <div v-if="isActionBlockVisible" class="custom-table__action-block" ref="actionBlock">
            <div v-if="handleCopy" class="custom-table__icon">
              <svg
                @click="handleCopy(row)"
                width="25"
                height="15"
                viewBox="0 0 25 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.625 0.0585938C8.03998 0.0585938 3.88297 2.56264 0.813173 6.64094C0.562276 6.97547 0.562276 7.43791 0.813173 7.77244C3.88297 11.8557 8.03998 14.3646 12.625 14.3646C17.21 14.3646 21.367 11.8606 24.4368 7.78228C24.6877 7.44775 24.6877 6.98531 24.4368 6.65078C21.367 2.56756 17.21 0.0585938 12.625 0.0585938ZM12.9546 12.2492C9.90941 12.4411 7.40044 9.93212 7.59231 6.88692C7.74973 4.37795 9.78642 2.33634 12.3003 2.17892C15.3455 1.98706 17.8545 4.49602 17.6626 7.54122C17.5003 10.0502 15.4636 12.0869 12.9546 12.2492Z"
                  fill="#2F2F34"
                />
                <path
                  d="M12.802 9.922C11.1638 10.0253 9.81093 8.67244 9.91424 7.03423C9.99787 5.68136 11.0949 4.5843 12.4478 4.50067C14.086 4.39736 15.4389 5.75023 15.3356 7.38844C15.2519 8.74131 14.1549 9.83837 12.802 9.922Z"
                  fill="#2F2F34"
                />
              </svg>
            </div>
            <div v-if="handleEdit" class="custom-table__icon">
              <svg
                @click="handleEdit(row)"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 19.9999L5.39452 19.4417L0.558452 14.6055L0 19.9999Z" fill="#2D2C2C" />
                <path
                  d="M12.0017 3.16246L16.8378 7.99853L6.32444 18.5118L1.48828 13.6758L12.0017 3.16246ZM19.336 5.50018C20.2213 4.61491 20.2217 3.17955 19.336 2.29401L17.706 0.66402C16.8208 -0.221431 15.3852 -0.221249 14.4999 0.66402L12.923 2.24101L17.7592 7.07699L19.336 5.50018Z"
                  fill="#2D2C2C"
                />
              </svg>
            </div>
            <div v-if="handleDelete" class="custom-table__icon">
              <svg
                @click="handleDelete(row)"
                width="14"
                height="20"
                viewBox="0 0 14 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7727 2.26059H10.631V2.14844C10.631 0.963745 9.66724 0 8.48254 0H5.50891C4.32422 0 3.36047 0.963745 3.36047 2.14844V2.26059H1.21875C0.571625 2.26059 0.046875 2.78519 0.046875 3.43246V4.32571C0.046875 4.54147 0.221741 4.71634 0.4375 4.71634H13.554C13.7697 4.71634 13.9446 4.54147 13.9446 4.32571V3.43246C13.9446 2.78519 13.42 2.26059 12.7727 2.26059ZM4.53235 2.14844C4.53235 1.60995 4.97043 1.17188 5.50891 1.17188H8.48254C9.02103 1.17188 9.45911 1.60995 9.45911 2.14844V2.26059H4.53235V2.14844Z"
                  fill="#2D2C2C"
                />
                <path
                  d="M0.552734 5.88672L1.49954 19.4533C1.52106 19.7603 1.77634 19.9985 2.08411 19.9985H11.9076C12.2153 19.9985 12.4706 19.7603 12.492 19.4533L13.4386 5.88672H0.552734ZM4.67688 18.0057C4.66849 18.0062 4.65994 18.0063 4.65155 18.0063C4.33951 18.0063 4.07996 17.76 4.06668 17.4453L3.67606 8.26556C3.66217 7.94223 3.91318 7.66895 4.23651 7.65521C4.56 7.64194 4.83313 7.89249 4.84686 8.21582L5.23749 17.3955C5.25122 17.7188 5.00021 17.992 4.67688 18.0057ZM7.5817 17.4204C7.5817 17.744 7.3194 18.0063 6.99576 18.0063C6.67212 18.0063 6.40982 17.744 6.40982 17.4204V8.24069C6.40982 7.91705 6.67212 7.65475 6.99576 7.65475C7.3194 7.65475 7.5817 7.91705 7.5817 8.24069V17.4204ZM9.92499 17.4453C9.91156 17.7602 9.65201 18.0063 9.33997 18.0063C9.33157 18.0063 9.32303 18.0062 9.31464 18.0059C8.9913 17.9921 8.7403 17.7188 8.75403 17.3955L9.14465 8.21582C9.15854 7.89249 9.43213 7.64163 9.755 7.65537C10.0783 7.6691 10.3293 7.94223 10.3156 8.26556L9.92499 17.4453Z"
                  fill="#2D2C2C"
                />
              </svg>
            </div>
            <div title="Publish" v-if="handlePublish" class="custom-table__icon">
              <svg
                @click="handlePublish(row)"
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                width="14"
                height="20"
                data-name="Layer 1"
                viewBox="0 0 120.86 122.88"
              >
                <path
                  class="cls-1"
                  d="M91.52,110.28V74H75.19c-6.93,1.24-13.85,5-20.78,9.36H41.73c-5.75.34-8.75,6.16-3.18,10,4.46,3.26,10.32,3.08,16.34,2.54,4.15-.21,4.33,5.37,0,5.39-1.5.12-3.14-.24-4.56-.24-7.51,0-13.7-1.44-17.49-7.37l-1.9-4.44L12.07,79.83C2.62,76.72-4.09,86.6,2.87,93.47a247.56,247.56,0,0,0,42,24.74c10.41,6.33,20.83,6.12,31.24,0l15.38-7.93ZM22.92,7.82H20.43a3,3,0,0,0-3.05,3V67.63a3,3,0,0,0,.89,2.15,3,3,0,0,0,2.16.9h41.8a3.06,3.06,0,0,0,3.05-3.05V64.82H61.87v2.45H20.79V11.21h2.13V7.82ZM36.79,52H66.65V49.16H36.79V52Zm0-8.76H66.65V40.4H36.79v2.86Zm0-26.26h10V14.14h-10V17Zm0,8.75H50.91V22.89H36.79v2.86Zm0,8.75H67V31.64H36.79V34.5Zm22.67-28L70.57,18.19H59.46V6.5ZM76.75,19.68a1.87,1.87,0,0,0-1.35-1.79L59,.68A1.84,1.84,0,0,0,57.61,0H30.32A3.31,3.31,0,0,0,27,3.33V58a3.31,3.31,0,0,0,3.32,3.33H73.43A3.31,3.31,0,0,0,76.75,58V19.68ZM73,21.9V57.57H30.71V3.7h25V20a1.87,1.87,0,0,0,1.87,1.86Zm47.82,48.57H96.44v43.46h24.42V70.47Z"
                />
              </svg>
            </div>
            <div title="Indexing" v-if="handleIndexing" class="custom-table__icon">
              <svg
                @click="handleIndexing(row)"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                fill="#000000"
                version="1.1"
                id="Capa_1"
                width="20px"
                height="20px"
                viewBox="0 0 379.384 379.384"
                xml:space="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M339.894,88.914l-94.404-77.635C237.674,4.847,224.146,0,214.024,0h-148.5C52.686,0,42.239,10.445,42.239,23.285    c0,0,0,212.941,0,281.92c0,4.25,2.303,3.052,2.953,2.365c3.037-3.211,12.341-12.666,12.341-12.666s1.95-1.701,1.95-5.951    c0-64.915,0-265.668,0-265.668c0-3.275,2.768-6.041,6.041-6.041h148.5c2.516,0,7.768,5.874,7.768,8.518v70.682    c0,12.84,10.445,23.285,23.283,23.285h87.726c1.6,0,4.311,1.099,4.311,4.974c0,0.059,0.01,218.841,0.01,218.841    c0,3.332-2.711,6.041-6.043,6.041c0,0-166.845,0-220.458,0c-5.315,0-8.537,3.213-8.537,3.213s-7.913,7.673-10.238,10.51    c-0.687,0.837-2.415,3.521,2.525,3.521c57.676,0,236.708,0,236.708,0c12.841,0,23.285-10.444,23.285-23.284V119.549    C354.362,108.868,348.14,95.697,339.894,88.914z M245.075,102.485c-3.33,0-6.041-2.709-6.041-6.041V29.805l85.068,72.68H245.075z"
                    />
                    <path
                      d="M159.065,318.619c20.877,0,40.398-8.233,54.965-23.186c29.525-30.307,28.892-78.98-1.414-108.506    c-14.41-14.041-33.424-21.773-53.541-21.773c-20.879,0-40.398,8.234-54.967,23.188c-29.523,30.304-28.889,78.979,1.414,108.506    C119.932,310.888,138.95,318.619,159.065,318.619z M116.458,200.374c11.293-11.592,26.428-17.977,42.617-17.977    c15.596,0,30.336,5.996,41.508,16.88c23.494,22.892,23.986,60.629,1.099,84.123c-11.295,11.592-26.431,17.978-42.616,17.978    c-15.598,0-30.338-5.994-41.51-16.88C94.061,261.607,93.569,223.869,116.458,200.374z"
                    />
                    <path
                      d="M108.305,314.898c-5.299-2.693-8.803-6.049-11.371-8.634c-1.827-1.839-6.278-6.688-9.215-11.629    c-0.312-0.526-1.14-1.351-2.543-0.086l-58.514,60.06c-2.229,2.289-2.182,5.981,0.104,8.211l15.256,14.922    c2.287,2.229,5.982,2.184,8.209-0.105l58.004-59.535C109.815,316.52,109.48,315.494,108.305,314.898z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <!--<div v-if="handleCancel" class="custom-table__icon">-->
            <!--<Cancel @click="handleCancel(row)" />-->
            <!--</div>-->
            <div v-if="handleButton" class="custom-table__button">
              <MainButton @click.native="handleButton(row)">Choose</MainButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>
<script>
import MainButton from "../atoms/buttons/MainButton.vue";
import ROUTE from "~/constants/routes";
export default {
  name: "CustomTable",
  props: {
    data: {
      type: [Array],
      default() {
        return [];
      },
    },
    handleFilter: {
      type: [Function, Boolean],
      default: false,
    },
    handleEdit: {
      type: [Function, Boolean],
      default: null,
    },
    handleDelete: {
      type: [Function, Boolean],
      default: null,
    },
    handlePublish: {
      type: [Function, Boolean],
      default: null,
    },
    handleIndexing: {
      type: [Function, Boolean],
      default: null,
    },
    handleCopy: {
      type: [Function, Boolean],
      default: null,
    },
    handleCancel: {
      type: [Function, Boolean],
      default: null,
    },
    handleButton: {
      type: [Function, Boolean],
      default: null,
    },
    handleAssignDriver: {
      type: [Function, Boolean],
      default: null,
    },
    onActionColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    onCustomStyleColumn: {
      type: [Array],
      default() {
        return [];
      },
    },
    customOrder: {
      type: [Array, Boolean],
      default: null,
    },
    isNumbered: {
      type: Boolean,
      default: false,
    },
    isActionBlockVisible: {
      type: Boolean,
      default: false,
    },
    isChooseList: {
      type: Boolean,
      default: false,
    },
    handleChoose: {
      type: [Function, Boolean],
      default: false,
    },
    deletedKeys: {
      type: [Array],
      default() {
        return [];
      },
    },
    booleanSwitcher: {
      type: [Array],
      default() {
        return [];
      },
    },
    images: {
      type: [Array],
      default() {
        return [];
      },
    },
    renameColumns: {
      type: [Array],
      default() {
        return [];
      },
    },
    direction: {
      type: Boolean,
      default: null,
    },
    order: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    queryParams: {
      type: String,
      default: "",
    },
  },
  components: {
    MainButton,
  },
  data() {
    return {
      actionHeaderBlockWidth: "auto",
    };
  },
  computed: {
    prepareData() {
      const { data } = this;
      const preparedData = data.map((item) => {
        if (this.customOrder.length) {
          const preparedItem = {};
          this.customOrder.map((key) => {
            preparedItem[key] = item[key];
            return true;
          });
          return preparedItem;
        }
        return item;
      });
      return preparedData;
    },
    getRowStyle() {
      const getAvalibleLength = Object.keys(this.prepareData[0]).length;
      if (!getAvalibleLength) return false;
      const totalLength = getAvalibleLength - this.deletedKeys.length;
      const gridArray = Array(totalLength).fill("1fr").join(" ");
      return {
        display: "grid",
        "grid-template-columns": `${gridArray} ${this.isNumbered ? "1fr" : ""} ${
          this.onActionColumn ? "1fr" : ""
        }`,
      };
    },
  },
  methods: {
    getRowClass(row) {
      if (row.fieldStatus) {
        return row.fieldStatus;
      }

      return "";
    },
    prepareColumnName(name) {
      if (this.renameColumns.length) {
        let newName = name;
        this.renameColumns.forEach((item) => {
          if (item.from === name) {
            newName = item.to;
          }
        });
        return newName;
      }
      return name;
    },
    getUrl(id, name) {
      if (name == "templates") {
        return ROUTE.TEMPLATE_EDIT.replace(":id", id);
      } else if (name == "categories") {
        return ROUTE.CATEGORY_EDIT.replace(":id", id);
      } else if (name == "keywords") {
        return ROUTE.KEYWORDS_EDIT.replace(":id", id);
      }
    },
    isElementShowed(element) {
      if (this.deletedKeys.length && typeof element === "string") {
        return !this.deletedKeys.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isImage(element) {
      if (this.images.length && typeof element === "string") {
        return this.images.some((el) => el.toLowerCase() === element.toLowerCase());
      }
      return false;
    },
    isSwitcher(element) {
      if (this.booleanSwitcher.length && typeof element === "string") {
        return this.booleanSwitcher.some(
          (el) => el.columnName.toLowerCase() === element.toLowerCase(),
        );
      }
      return false;
    },
    handleActionOnClick(item, row) {
      item.action(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  min-width: 100%;
  background: #fff;
  &__header {
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    background: $mainSecondColor;
  }
  &__container {
    margin-top: 20px;
    border-right: 1px solid #dbdbdb;
    border-left: 1px solid #dbdbdb;
  }
  &__header-item {
    display: flex;
    align-items: center;
    padding-right: 5px;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: left;
  }
  &__header-item--text {
    display: flex;
    align-items: center;
    width: max-content;
    word-wrap: break-word;
    font-size: 10px;
  }
  &__header-item--container {
    display: flex;
    justify-content: center;
  }
  &__filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-left: 5px;
    &-item {
      height: 6px;
      width: 10px;
    }
    &-item.active {
      svg {
        path {
          fill: darkblue;
        }
      }
    }
  }
  &__filter--asc {
    position: relative;
    transform: rotate(180deg);
    top: 5px;
  }
  &__filter--desc {
    position: relative;
    top: -5px;
  }
  &__button {
    /deep/.button-main {
      width: 136px;
      height: 36px;
    }
  }
  &__row {
    display: flex;
    width: 100%;
    padding: 16px 16px;
    position: relative;
    align-items: right;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background: #dbdbdb;
      bottom: 0;
    }

    &.red {
      background: #eb59595d;
    }

    &.green {
      background: #59eb715d;
    }
  }
  &__row.choose {
    cursor: pointer;
    &:hover {
      background: $mainSecondColor;
      .custom-table__row-item {
        color: #fff;
      }
    }
  }
  &__row-item {
    width: 100%;
    font-size: 14px;
    text-align: left;
    display: flex;
    align-items: center;
    color: black;
    padding-right: 5px;
    overflow: hidden;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .link {
      color: $mainSecondColor;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__none-item {
    display: none;
  }
  &__number-of-item {
    width: 15%;
  }
  &__action-block {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    position: relative;
  }
  .switcher {
    text-align: center;
    width: 100%;
    background: #ff4d4d;
    border-radius: 25px;
    padding: 4px 0;
    cursor: pointer;
    margin: 5px;
    &--disabled {
      background: #c4c4c4;
    }
  }
  &__icon {
    display: flex;
    svg {
      margin: auto 0;
      cursor: pointer;
      path {
        fill: $mainSecondColor;
      }
      rect {
        fill: $mainSecondColor;
      }
    }
  }
  &__icon:hover {
    path {
      fill: darkblue;
    }
    rect {
      fill: darkblue;
    }
  }
  &__icon:active {
    path {
      fill: darkblue;
    }
    rect {
      fill: darkblue;
    }
  }
}
.custom-table.choose {
  .custom-table__container {
    margin-top: 0;
    margin-bottom: 2px;
  }
  .custom-table__row {
    cursor: pointer;
    &:hover {
      background: $mainSecondColor;
      .custom-table__row-item {
        color: #fff;
      }
    }
  }
}
.red {
  color: #eb5959;
}
.green {
  color: #5fbf68;
}
.column-preview {
  div {
    padding-right: 10px;
  }
  img {
    width: 100%;
  }
}
/deep/.custom-table__icon {
  margin-right: 15px;
}
</style>
