<template>
  <Main>
    <ToolsPanel :breadCrumbFirst="'Dictionaries'" :title="'Dictionaries List'">
      <div class="list__buttons">
        <MainButton @click.native="handleCreate()">Create</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="list">
        <div v-if="dictionaryList.length">
          <CustomTable
            v-if="dictionaryList.length"
            :data="dictionaryList"
            :deleted-keys="tableOptions.deletedKeys"
            :rename-columns="tableOptions.renameColumn"
            :custom-order="tableOptions.customOrder"
            :is-action-block-visible="tableOptions.isActionBlockVisible"
            :handle-delete="handleDelete"
            :handle-edit="handleEdit"
            :handleFilter="handleFilter"
            :handleCopy="handleDetailPage"
            :direction="directionSort"
            :order="orderSort"
          >
          </CustomTable>
          <div class="pagination">
            <Pagination
              :maxPage="dictionary.lastPage"
              :page="page"
              :dataList="dictionary"
              :urlList="'DICTIONARY'"
            />
          </div>
        </div>
        <div class="list__no-have" v-else>No Dictionaries found by this parameter.</div>
      </div>
    </ContentContainer>
    <ModalError
      :error-modal="false"
      :handle-close-error-modal="
        () => {
          this.error = {};
        }
      "
    />
    <MainModal v-if="deleteModal" :on-close="handleCloseConfirmModal" :modal-center="true">
      <div class="list__confirm">
        <div class="list__confirm-title">
          Do you want to delete the Dictionary - {{ deleteRow.name }}?
        </div>
        <div class="list__confirm-buttons">
          <div class="list__confirm-button">
            <MainButton buttonWhite @click.native="handleCloseConfirmModal">CANCEL</MainButton>
          </div>
          <div class="list__confirm-button">
            <MainButton @click.native="handleDeleteRow" :button-cancel="true">DELETE</MainButton>
          </div>
        </div>
      </div>
    </MainModal>

    <MainModal v-if="createModal || editModal" :on-close="handleCloseCreate" :modal-center="true">
      <div class="list__confirm">
        <form @submit.prevent="handleSubmit(editModal)">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">SEO Name</p>
          <div class="input-container">
            <InputText
              :readonly="editModal"
              :onChange="setSeoName"
              :id="'seo_name'"
              :label="'seoName'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEOName'"
              :error-messages="validationErrors['seoName']"
              isBordered
            />
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
      </div>
    </MainModal>
    <MainModal v-if="deleteModalSuccess" :on-close="handleCloseSuccessModal">
      <div class="list__success-delete">Dictionary {{ deleteRow.name }} successfully deleted.</div>
    </MainModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import referencesApi from "~/api/references";
import { seoTransform } from "~/helpers/seoTransform";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import CustomTable from "~/components/molecules/CustomTable.vue";
import Pagination from "~/components/molecules/Pagination.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "DictionariesList",
  metaInfo: {
    title: "Dictionaries List",
  },
  data() {
    return {
      deleteModal: false,
      deleteRow: null,
      deleteModalSuccess: false,
      directionSort: true,
      createModal: false,
      editModal: false,
      formDataToSend: {
        name: "",
        id: null,
        seo_name: "",
        status: false,
      },
      orderSort: "id",
      tableOptions: {
        deletedKeys: ["null"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "id",
            to: "ID",
          },
          {
            from: "name",
            to: "Name",
          },
          {
            from: "seoName",
            to: "Seo name",
          },
          {
            from: "status",
            to: "Status",
          },
        ],
        customOrder: ["id", "name", "seoName", "status", "null"],
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    CustomTable,
    Pagination,
    MainModal,
    ModalError,
    InputText,
    MainCheckbox,
  },
  mounted() {
    this.getList();
  },
  watch: {
    $route(to, from) {
      this.getList();
    },
  },
  computed: {
    ...mapState("references", [
      "error",
      "loadingProcess",
      "validationErrors",
      "dictionary",
      "dictionaryList",
    ]),
    page() {
      return +this.$route.params.page;
    },
  },
  methods: {
    getList() {
      let url = `?page=${this.page}`;
      if (this.orderSort.length) {
        url = url + `&sortField=${this.orderSort}&sortDest=${this.directionSort ? "asc" : "desc"}`;
      }
      this.$store.dispatch("references/getDictionary", url);
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      this.getList();
    },
    handleDelete(row) {
      this.deleteModal = true;
      this.deleteRow = row;
    },
    handleEdit(row) {
      this.formDataToSend.id = row.id;
      this.formDataToSend.name = row.name;
      this.formDataToSend.seo_name = row.seoName;
      this.formDataToSend.status = row.status;
      this.editModal = true;
    },
    handleCreate() {
      this.createModal = true;
    },
    handleDetailPage(row) {
      this.$router.push({
        path: ROUTE.DICTIONARY_DETAIL.replace(":id", `${row.id}`),
      });
    },
    handleCloseConfirmModal() {
      this.deleteModal = false;
    },
    handleCloseCreate() {
      this.createModal = false;
      this.editModal = false;

      this.formDataToSend.name = "";
      this.formDataToSend.seo_name = "";
      this.formDataToSend.id = null;
      this.formDataToSend.status = false;
    },
    handleDeleteRow() {
      const url = `/${this.deleteRow.id}?soft=1`;
      this.$store.commit("references/setLoading", true);
      referencesApi
        .deleteDictionary(url)
        .then((res) => {
          this.$store.commit("references/setLoading", false);
          if (res.success) {
            this.getList();
            this.handleCloseConfirmModal();
            this.deleteModalSuccess = true;
          }
        })
        .catch((error) => {
          this.$store.commit("references/setLoading", false);
          console.log(error);
        });
    },

    handleCloseSuccessModal() {
      this.deleteModalSuccess = false;
    },
    closeModal(edit) {
      this.error = null;
    },
    handleSubmit(edit = false) {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name.length != undefined
            ? this.formDataToSend.seo_name.trim()
            : "",
        status: this.formDataToSend.status ? 1 : 0,
      };
      const url = `/${this.formDataToSend.id}`;
      if (edit) {
        referencesApi
          .editDictionary(url, data)
          .then((res) => {
            this.$store.commit("references/setLoading", false);
            if (res.success) {
              this.getList();
              this.handleCloseCreate();
            }
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            const { data } = error.response;
            this.$store.commit("references/setError", data);
            if (data.errors) {
              this.$store.commit("references/setValidationErrors", data.errors);
            }
          });
      } else {
        referencesApi
          .createDictionary(data)
          .then((res) => {
            this.$store.commit("references/setLoading", false);
            if (res.success) {
              this.getList();
              this.handleCloseCreate();
            }
          })
          .catch((error) => {
            this.$store.commit("references/setLoading", false);
            const { data } = error.response;
            this.$store.commit("references/setError", data);
            if (data.errors) {
              this.$store.commit("references/setValidationErrors", data.errors);
            }
          });
      }
    },

    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      if (this.editModal) {
        return;
      }
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.custom-table__header,
/deep/.custom-table__row {
  grid-template-columns: 0.4fr 1fr 1fr 1fr 1fr !important;
}
</style>
