export default {
  //login
  //
  LOG_IN: "/user/login",
  LOG_OUT: "/user/logout",
  GET_PROFILE: "/user/profile",

  //categories
  //
  CATEGORIES_GROUP_LIST: "/category-groups",
  CATEGORIES_GROUP_CHECK_SEO: "/category-groups/checkSeoName",
  CATEGORIES_GROUPS_TREE: "/category-groups/getTree",

  CATEGORIES_MAIN_URL: "/category",
  CATEGORY_CHECK_SEO: "/category/checkSeoName",
  CATEGORIES_TREE: "/category/getTree",
  CATEGORIES_FILTER: "/category/filter",
  CATEGORIES_IMAGE: "/category-image",
  CATEGORIES_GET_ACTIVE: "/category/getActive",

  //templates
  //
  TEMPLATES_MAIN_URL: "/templates",
  TEMPLATE_CHECK_SEO: "/templates/checkBySeoName",
  TEMPLATE_IMAGES_UPLOAD: "/templates-images",
  TEMPLATE_IMAGES_DELETE: "/templates-images",
  TEMPLATE_IMAGES_PREVIEW: "/templates-images/preview",
  TEMPLATE_IMAGES_SLIDER: "/templates-images-slider",
  TEMPLATE_INSTRUCTION_UPLOAD: "/templates-instruction",
  TEMPLATE_INSTRUCTION_DELETE: "/templates-instruction",
  TEMPLATE_VIDEO_UPLOAD: "/templates-videos",
  TEMPLATES_FILTER: "/templates/filter",
  TEMPLATES_IMAGE_SIZES: "/templates-system/getAllowableImageSizes",
  TEMPLATES_STATISTICS: "/templates/getStatistics",

  TEMPLATES_QUICK_EDIT: "/templates/quickUpdate",
  TEMPLATES_QUICK_LIST: "/templates/getListWithoutRelations",

  TEMPLATES_SOURCE_FILE_LOAD: "/templates-files",

  TEMPLATES_DOWNLOADS: "/templates-download/download-list",

  TEMPLATES_GET_BY_URL: "/templates/getByUrl",

  TEMPLATES_LAYOUT: "/templates-layouts",

  TEMPLATES_ATTRIBUTES: "/attributes",

  //DICTIONARY

  DICTIONARY: "/dictionaries",
  DICTIONARY_ITEMS: "/dictionary-items",

  //content plans
  //
  CONTENT_PLANS: "/content-plans",
  CONTENT_PLAN_ITEMS: "/content-plans/items",
  CONTENT_PLAN_ITEM: "/content-plans-items",
  CONTENT_PLAN_ITEM_TYPES: "/content-plans-items-types/get-all",

  //holiday
  //
  HOLIDAYS: "/holidays",

  //keywords
  //
  KEYWORDS: "/content-plans-keywords",
  KEYWORDS_ARRAY: "/content-plans-keywords/store-many",

  KEYWORD_REQUIREMENT: "/chat-gpt-admin/content-plan-keyword-requirements",

  //templates type
  //
  TEMPLATES_TYPES: "/templates-types",

  //template color
  TEMPLATES_COLOR: "/templates-colors",
  TEMPLATES_COLOR_ALL: "/templates-colors/get-all",
  //template style
  TEMPLATES_STYLE: "/templates-styles",
  TEMPLATES_STYLE_ALL: "/templates-styles/get-all",

  //templates statutes
  //
  TEMPLATES_STATUSES: "/templates-statuses",

  //templates formats
  //
  TEMPLATES_FORMATS: "/templates-formats",

  //templates tags
  //
  TEMPLATES_TAGS: "/templates-tags",
  TEMPLATES_TAGS_ALL: "/templates-tags/get-all",

  //templates tags
  //
  TEMPLATES_SIZES: "/templates-sizes",
  TEMPLATES_SIZES_ALL: "/templates-sizes/get-all",

  //templates fonts
  //
  TEMPLATES_FONTS: "/templates-fonts",
  TEMPLATES_FONTS_ALL: "/templates-fonts/get-all",
  TEMPLATES_FONTS_FILTER: "/templates-fonts/filter",

  //layout fonts
  LAYOUT_FONTS: "/templates-layouts-fonts",
  LAYOUT_FONTS_FILTER: "/templates-layouts-fonts/filter",

  //blog groups
  //
  BLOG_GROUPS_MAIN: "/blog-category-groups",
  BLOG_GROUPS_CHECK_SEO: "/blog-category-groups/checkSeoName",
  BLOG_GROUPS_GET_TREE: "/blog-category-groups/getTree",

  //blog categories
  //
  BLOG_CATEGORIES_MAIN: "/blog-category",
  BLOG_CATEGORIES_CHECK_SEO: "/blog-category/checkSeoName",
  BLOG_CATEGORIES_GET_TREE: "/blog-category/getTree",

  //blog posts
  //
  BLOG_POSTS_MAIN: "/blog-post",
  BLOG_POSTS_CHECK_SEO: "/blog-post/checkSeoName",
  BLOG_POST_IMAGE: "/blog-post-image",
  BLOG_POST_IMAGE_CONTENT: "/blog-post-content-image",
  BLOG_POST_IMAGE_SIZES: "/blog-system/getAllowableImageSizes",
  BLOG_POST_STATUSES: "/blog-posts-statuses",

  //user groups
  //
  USER_GROUPS: "/user-group",

  //user
  //
  USER: "/user",
  USER_FILTER: "/user/filter",

  //subscription

  SUBSCRIPTION: "/subscription",
  GET_USER_ACTIVE_SUBSCRIPTION: "/subscription-admin/getActiveByUserId",
  GET_USER_SUBSCRIPTION: "/subscription-admin/getByUserId",
  ASSIGN_SUBSCRIPTION: "/subscription-admin/assign",
  DE_ASSIGN_SUBSCRIPTION: "/subscription-admin/de-assign",

  //faq

  FAQ_GROUP: "/faq-group",
  FAQ_ITEM: "/faq",

  //static

  STATIC: "/static-pages",

  //seo

  SEO: "/seo-metadata-admin",

  //social

  SOCIAL_FB: "/social-facebook-admin",

  //indexing

  INDEXING_LIST: "/google-indexing-api/getListPages",
  INDEXING_FILTER: "/google-indexing-api/filter",
  INDEXING_CHECK: "/google-indexing-api/addToCheckingIndexQueue",
  INDEXING_ADD: "/google-indexing-api/addToIndexingQueue",
  INDEXING_STATUSES: "/google-indexing-api/statuses",
  INDEXING_LIMIT: "/google-indexing-api/getLimits",
  INDEXING_ITEM: "/google-indexing-api/getByPath",
  INDEXING_EXPORT: "/google-indexing-api/export",
  INDEXING_STATISTICS: "/google-indexing-api/getStatistics",

  //lang

  LANG: "/lang-admin",
  LANG_COLOR: "/lang-color-admin",
  LANG_STYLE: "/lang-style-admin",
  LANG_TEMPLATE: "/lang-template-admin",
  LANG_CATEGORY: "/lang-category-admin",
  LANG_STATIC: "/lang-static-pages-admin",
  LANG_FAQ: "/lang-faq-items-admin",
  LANG_SEO: "/lang-metadata-items-admin",
  LANG_HOLIDAY: "/lang-holiday-admin",
  LANG_ATTRIBUTES: "/lang-attribute-admin",
  TRANSLATION: "/chat-gpt-admin/translate",

  // Crop

  CROP: "/ai-image-tools/crop",
  REMOVE_BACKGROUND: "/ai-image-tools/remove-background",
  REMOVE_BACKGROUND_IMAGE: "/ai-image-tools/image",
  IMAGE_CONVERT: "/ai-image-tools/convert",
};
