<template>
  <div
    v-if="chooseSite != null"
    v-click-outside="handleClickOutside"
    :class="['header__site-controller', colorBlack && 'black']"
  >
    <div class="header__site-container">
      <a :href="redirect ? '/' : '#'" class="header__site-choose">{{ chooseSite.name }}</a>
      <div @click="handleOpenSiteList" :class="['header__site-button', siteList && 'active']">
        <SelectIcon />
      </div>
    </div>
    <ul v-if="siteList" class="header__site-list">
      <li
        v-for="site in sites"
        :key="site.id"
        class="header__site-list-item"
        @click="handleChangeSite(site)"
      >
        {{ site.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { cleanSite, setSite, getSite } from "~/helpers/userAuth";
import ROUTE from "~/constants/routes";

import SelectIcon from "~/assets/images/icons/select-icon-small.svg";
export default {
  name: "HeaderSiteController",
  data() {
    return {
      siteList: false,
      chooseSite: null,
      sites: [
        {
          name: "TheGooDocs",
          id: 1,
          key: "sNmzWZvR7XsnyAP4",
        },
        {
          name: "ElegantFlyer",
          id: 2,
          key: "XZr2sddtKwL3x7Jy",
        },
        {
          name: "LoveSlides",
          id: 3,
          key: "3RPtXE9h3KA6Xg4f",
        },
        {
          name: "4Templates",
          id: 4,
          key: "MYBhioy6owQpYxUByUjJ",
        },
        {
          name: "BirthDayYou",
          id: 5,
          key: "SeGXyH3ReXaG3Hfh6DE8",
        },
        {
          name: "MyGoodSheets",
          id: 6,
          key: "BcQr60Q2oJem",
        },
        {
          name: "API KDTeam",
          id: 7,
          key: "t3qDp5WU4Ng3",
        },
      ],
    };
  },
  props: {
    colorBlack: {
      type: Boolean,
      default: false,
    },
    redirect: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SelectIcon,
  },
  directives: {
    ClickOutside,
  },
  mounted() {
    this.getSiteData();
  },
  methods: {
    handleChangeSite(site) {
      this.chooseSite = site;
      cleanSite();
      setSite(site);
      this.siteList = false;
      this.handleLogout();
    },
    handleOpenSiteList() {
      this.siteList = !this.siteList;
    },
    handleClickOutside() {
      this.siteList = false;
    },
    handleLogout() {
      this.$store.dispatch("users/logout");
    },
    getSiteData() {
      const siteData = getSite();
      if (siteData != null) {
        this.chooseSite = siteData;
      } else {
        this.chooseSite = {
          name: "TheGooDocs",
          id: 1,
          key: "sNmzWZvR7XsnyAP4",
        };
        cleanSite();
        setSite({
          name: "TheGooDocs",
          id: 1,
          key: "sNmzWZvR7XsnyAP4",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__site-choose {
    font-size: 20px;
    font-weight: bold;
    color: $g-color-white;
  }

  &__site-controller {
    position: relative;
    min-width: 180px;

    &.black {
      .header__site-choose {
        color: #000;
      }

      .header__site-button {
        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  &__site-list {
    width: calc(100% + 11px);
    list-style: none;
    position: absolute;
    top: 120%;
    left: -11px;
    background: $g-color-white;
    z-index: 100;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid $mainDisabled;
    border-radius: 5px;
  }

  &__site-list-item {
    padding: 5px 15px;
    color: $mainColor;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: $mainSecondColor;
      color: $g-color-white;
    }
  }

  &__site-container {
    display: flex;
    cursor: pointer;
  }

  &__site-button {
    margin-left: 10px;

    svg {
      position: relative;
      top: 2px;
      path {
        fill: $g-color-white;
      }
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
